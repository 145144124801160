import React from "react";

const CompanyBar = () => {
    return (
        <div className="bg-secondary flex flex-col items-center justify-center p-4 min-h-[20vh] text-white">
            <h1 className="text-3xl lg:text-5xl font-bold">
                AS Maskiner Sverige AB
            </h1>
            <p className="mt-4 text-sm sm:text-lg max-w-sm sm:max-w-none text-center">
                Sveriges största utbud av begagnade kvalitetsmaskiner för trä- och
                plastindustrin
            </p>
        </div>
    )
}

export default CompanyBar;