import React, { useEffect } from "react";
import Contact from "../components/contact";
import Heading from "../components/heading";
import { Helmet } from "react-helmet-async";
import CompanyBar from "../components/companyBar.jsx";

const AboutUs = () => {
  return (
    <div>
      <Helmet>
        <title>AS Maskiner - Om oss</title>
        <meta
          name="description"
          content="Lär känna AS Maskiner, ledande inom begagnade kvalitetsmaskiner sedan 2024. Vi förenar innovation med tradition för att leverera de bästa maskinerna till ett bra pris. Upptäck vår historia och våra värderingar."
        />
        <meta
          name="keywords"
          content="AS Maskiner, begagnade maskiner, begagnad snickerimaskin, CNC fräs, Homag CNC, Biesse CNC, SCM CNC, Morbidelli CNC, begagnad CNC, begagnad plattuppdelningssåg, begagnad bredbandputs, begagnad kantlistmaskin, begagnad listhyvel, begagnad hyvel, begagnad justersåg, begagnad spånsug, begagnad väggsåg, begagnad fanérpress"
        />
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "AboutPage",
            "url": "https://www.asmaskiner.se/about-us",
            "name": "Om oss",
            "description": "Lär känna AS Maskiner, ledande inom begagnade kvalitetsmaskiner sedan 2024. Vi förenar innovation med tradition för att leverera de bästa maskinerna till ett bra pris. Upptäck vår historia och våra värderingar."
          }`}
        </script>
      </Helmet>
      <CompanyBar/>
      <div className="sm:p-16">
        <div className="container mx-auto max-w-7xl p-4 sm:p-0">
          <Heading sx="w-fit">Om oss</Heading>
          <p className="mt-4">
            AS Maskiner grundades med idén om att göra den begagnade marknaden
            av maskiner för trä-/plastindustrin professionellare, enklare och
            säkrare samtidigt som vi tycker att det saknades en marknadsplats
            för kvalitetsmaskiner på den svenska marknaden. Vi har byggt upp
            samarbeten med bolag runt om i Europa för att kunna bistå den
            svenska marknaden med Sveriges största utbud av begagnade
            kvalitetsmaskiner. Vi importerar allt från CNC-maskiner till
            kantlistmaskiner, plattuppdelningssågar, bredbandputsar,
            justersågar, väggsågar, spånsugsanläggningar och listhyvlar. Ett
            axplock av vilka fabrikat som vi erbjuder är SCM, Homag, Biesse,
            Felder, Masterwood, Holzher, Weinig, Altendorf, Martin och IMA.
          </p>
        </div>
      </div>
      <div className="bg-secondary sm:p-16">
        <div className="container mx-auto max-w-7xl p-4 sm:p-0">
          <div className="flex flex-col sm:flex-row justify-between space-y-8 sm:space-x-4 sm:space-y-0 items-stretch">
            <SellingPoint
              heading="Professionellare"
              points={[
                "Lång erfarenhet av maskinbranschen",
                "Kontakt med specialister",
                "Funktionskontroll av maskiner",
                "God kontakt med agenturer",
              ]}
            />
            <SellingPoint
              heading="Enklare"
              points={[
                "Stort utbud",
                "Jämför olika fabrikat",
                "Vi guidar dig rätt",
                "Tar fram förslag på frakt",
              ]}
            />
            <SellingPoint
              heading="Säkrare"
              points={[
                "Funktionskontroll av maskiner",
                "Vi tar fram rätt maskin",
                "Svensk kontakt",
              ]}
            />
          </div>
        </div>
      </div>
      <div className="sm:p-16">
        <div className="container mx-auto max-w-7xl p-4 sm:p-0">
          <Heading sx="text-center w-fit mb-4">Kontakta oss</Heading>
          <Contact sendButtonText="Skicka" />
        </div>
      </div>
    </div>
  );
};

function SellingPoint({ heading, points }) {
  return (
    <div className="flex-1 flex flex-col">
      <Heading size="text-2xl" sx="text-center">
        {heading}
      </Heading>
      <div className="border-l border-r border-b border-primary flex-1">
        <ul className="list-disc ml-4 p-4 space-y-2 text-white">
          {points.map((point, index) => (
            <li key={index}>{point}</li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default AboutUs;
