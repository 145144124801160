async function getMachines(jwt) {
  const headers = jwt
    ? {
        Authorization: jwt,
      }
    : {};

  const response = await fetch("/api/machines", {
    method: "GET",
    headers,
  });

  const machines = await response.json();
  return machines;
}

async function createSellProposal(sellProposal) {
  const response = await fetch("/api/sell-proposals", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(sellProposal),
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to create sell proposal"),
    };
  }

  return response;
}

async function contact(message) {
  const response = await fetch("/api/contact", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(message),
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to send message"),
    };
  }

  return response;
}

async function resendOtp(jwt) {
  const response = await fetch("/api/users/otp/resend", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwt,
    },
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to resend otp"),
    };
  }

  return response;
}

async function sendForgotPasswordOtp(email) {
  const response = await fetch("/api/users/otp/forgot-password", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
    }),
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to send otp"),
    };
  }

  return response;
}

async function verifyOtp(otp, jwt) {
  const response = await fetch("/api/users/otp/verify", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwt,
    },
    body: JSON.stringify({
      otp: otp,
    }),
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to verify otp"),
    };
  }

  return response;
}

async function verifyForgotPasswordOtp(otp, email, newPassword) {
  const response = await fetch("/api/users/otp/forgot-password/verify", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      otp,
      email,
      newPassword,
    }),
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to verify otp"),
    };
  }

  return response;
}

async function login(loginRequest) {
  const response = await fetch("/api/users/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(loginRequest),
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to login"),
    };
  }

  return response;
}

async function register(registerRequest) {
  const response = await fetch("/api/users/register", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(registerRequest),
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to register"),
    };
  }

  return response;
}

async function authorize(jwt) {
  const response = await fetch("/api/users/authorize", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: jwt,
    },
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Not authorized"),
    };
  }

  return response;
}

async function createMachine({ formData, jwt }) {
  const response = await fetch("/api/admin/machines", {
    method: "POST",
    headers: {
      Authorization: jwt,
    },
    body: formData,
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to create machine"),
    };
  }

  return response;
}

async function deleteMachine({ id, jwt }) {
  const response = await fetch(`/api/admin/machines/${id}`, {
    method: "DELETE",
    headers: {
      Authorization: jwt,
    },
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to delete machine"),
    };
  }

  return response;
}

async function updateFeatured({ id, featured, jwt }) {
  const response = await fetch(
    `/api/admin/machines/${id}/featured?featured=${featured}`,
    {
      method: "PUT",
      headers: {
        Authorization: jwt,
      },
    }
  );

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to update featured"),
    };
  }

  return response;
}

async function updateMachine({ id, machine, jwt }) {
  const response = await fetch(`/api/admin/machines/${id}`, {
    method: "PUT",
    headers: {
      Authorization: jwt,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(machine),
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to update machine"),
    };
  }

  return response;
}

/**
 * @returns {Promise<string>} url to the uploaded file
 * @throws {{ statusCode, error }}
 */
async function uploadFile({ file, jwt, compress }) {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("compress", compress);

  const response = await fetch(`/api/admin/file/upload`, {
    method: "POST",
    headers: {
      Authorization: jwt,
    },
    body: formData,
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to upload file"),
    };
  }

  const json = await response.json();
  return json.url;
}

async function getSellProposals({ jwt }) {
  const response = await fetch(`/api/admin/sell-proposals`, {
    headers: {
      Authorization: jwt,
    },
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to get sell proposals"),
    };
  }

  return await response.json();
}

async function publishMachine({ jwt, id }) {
  const response = await fetch(`/api/admin/machines/${id}/publish`, {
    method: "PUT",
    headers: {
      Authorization: jwt,
    },
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to publish machine"),
    };
  }

  return;
}

async function unpublishMachine({ jwt, id }) {
  const response = await fetch(`/api/admin/machines/${id}/unpublish`, {
    method: "PUT",
    headers: {
      Authorization: jwt,
    },
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to unpublish machine"),
    };
  }

  return;
}

async function addSellProposalEvent({ sellProposalId, description, jwt }) {
  const response = await fetch(`/api/admin/sell-proposals/events`, {
    method: "PUT",
    headers: {
      Authorization: jwt,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      sellProposalId,
      description,
    }),
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to add sell proposal event"),
    };
  }

  return await response.json();
}

/**
 * @param jwt {Jwt}
 * @returns {Promise<NewsArticle[]>}
 * @throws {{ status: number, error: Error }} status code and error.
 */
async function getNews(jwt) {
  const headers = jwt
    ? {
        Authorization: jwt,
      }
    : {};

  const response = await fetch(`/api/news`, {
    method: "GET",
    headers,
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to get news"),
    };
  }

  const news = await response.json();
  // Map articles to have dates
  // (createdAt, updatedAt) instead of strings
  return news.map((article) => ({
    ...article,
    createdAt: new Date(article.createdAt),
    updatedAt: new Date(article.updatedAt),
  }));
}

/**
 * @param {{
 *  authorName: string,
 *  title: string,
 *  image: File,
 *  body: string,
 *  published: boolean
 * }} data
 * @param jwt {Jwt}
 * @returns {Promise<number>} the id of the created article.
 * @throws {{ status: number, error: Error }} status code and error.
 */
async function createNewsArticle(
  { authorName, title, image, body, published },
  jwt
) {
  const fileUrl = await uploadFile({ file: image, jwt: jwt, compress: false });
  const payload = JSON.stringify({
    authorName: authorName,
    title: title,
    imageUrl: fileUrl,
    body: body,
    published: published,
  });

  const response = await fetch(`/api/admin/news`, {
    method: "POST",
    headers: {
      Authorization: jwt,
      "Content-Type": "application/json",
    },
    body: payload,
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to add news article"),
    };
  }

  const article = await response.json();
  return article.id;
}

/**
 * Updates target article. If a file (image) is provided, the image
 * replaces the old one. If null, the image is not updated.
 * @param image {File|null} the replacement image. Null to keep the same image.
 * @param updatedNewsArticle {UpdateNewsArticleRequest} update details.
 * @param articleId {number} the target article.
 * @param jwt {Jwt}
 * @returns {Promise<void>}
 * @throws {{ status: number, error: Error }} status code and error.
 */
async function updateNewsArticle(image, articleId, updatedNewsArticle, jwt) {
  if (image) {
    try {
      updatedNewsArticle.imageUrl = await uploadFile({
        file: image,
        jwt: jwt,
        compress: false,
      });
    } catch (error) {
      throw error;
    }
  }
  const response = await fetch(`/api/admin/news/${articleId}`, {
    method: "PUT",
    headers: {
      Authorization: jwt,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(updatedNewsArticle),
  });
  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to update news article"),
    };
  }
}

/**
 * @param idsToDelete {number[]} the ids of the articles to delete.
 * @param jwt {Jwt}
 * @returns {Promise<void>} the number of deleted articles.
 * @throws {{ status: number, error: Error }} status code and error.
 */
async function deleteNews(idsToDelete, jwt) {
  const response = await fetch(`/api/admin/news`, {
    method: "DELETE",
    headers: {
      Authorization: jwt,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(idsToDelete),
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to delete news"),
    };
  }
}

/**
 * Publish or unpublish specified article.
 * @param articleId {number} the article to delete.
 * @param publish {boolean} publish or unpublish
 * @param jwt {Jwt}
 * @returns {Promise<void>}
 * @throws {{ status: number, error: Error }} status code and error.
 */
async function toggleNewsPublished(articleId, publish, jwt) {
  const endpoint = publish
    ? `/api/admin/news/${articleId}/publish`
    : `/api/admin/news/${articleId}/unpublish`;

  const response = await fetch(endpoint, {
    method: "PUT",
    headers: { Authorization: jwt },
  });

  if (!response.ok) {
    throw {
      status: response.status,
      error: Error("Unable to toggle news publish"),
    };
  }
}

export {
  getMachines,
  createSellProposal,
  contact,
  login,
  register,
  authorize,
  createMachine,
  deleteMachine,
  getSellProposals,
  addSellProposalEvent,
  updateFeatured,
  verifyOtp,
  resendOtp,
  sendForgotPasswordOtp,
  verifyForgotPasswordOtp,
  updateMachine,
  publishMachine,
  unpublishMachine,
  uploadFile,
  createNewsArticle,
  deleteNews,
  updateNewsArticle,
  getNews,
  toggleNewsPublished,
};
